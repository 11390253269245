import React from "react";
import styled, { css } from "styled-components";
import { Radio as BaseRadio, IRadioProps, RadioStyles as BaseRadioStyles } from "@diana-ui/radio";
import * as RadioStyles from "./Radio.style";

const StyledRadio = styled(BaseRadio)(
  ({ theme }) => css`
    cursor: pointer;
    &:hover ${RadioStyles.StyledInnerPath}:not(.checked):not(.disabled) {
      stroke-dashoffset: 38px;
      stroke: ${theme.colors.neutral["500"]};
    }

    ${BaseRadioStyles.StyledLabel} {
      margin-left: ${theme.spaceUnit.xs};
    }

    &.error {
      color: ${theme.colors.red["400"]};

      ${BaseRadioStyles.StyledLabel} {
        color: ${theme.colors.red["400"]};
      }
    }
  `,
);

const Radio: React.FC<IRadioProps> = ({
  children,
  disabled,
  selectedValue,
  value,
  ref,
  ...props
}) => (
  <>
    <StyledRadio disabled={disabled} selectedValue={selectedValue} value={value} {...props}>
      <RadioStyles.StyledSVG
        width="20px"
        height="20px"
        viewBox="0 0 20 20"
        className={disabled ? "disabled" : ""}>
        <RadioStyles.StyledCircle cx="10" cy="10" r="9" />
        <RadioStyles.StyledInnerPath
          d="M10,7 C8.34314575,7 7,8.34314575 7,10 C7,11.6568542 8.34314575,13 10,13 C11.6568542,13 13,11.6568542 13,10 C13,8.34314575 11.6568542,7 10,7 Z"
          className={[value === selectedValue && "checked", disabled && "disabled"]
            .filter(Boolean)
            .join(" ")}
          style={{ transform: "scale(0.7)", transformOrigin: "center" }}
        />
        <RadioStyles.StyledOuterPath
          d="M10,1 L10,1 L10,1 C14.9705627,1 19,5.02943725 19,10 L19,10 L19,10 C19,14.9705627 14.9705627,19 10,19 L10,19 L10,19 C5.02943725,19 1,14.9705627 1,10 L1,10 L1,10 C1,5.02943725 5.02943725,1 10,1 L10,1 Z"
          className={[value === selectedValue && "checked", disabled && "disabled"]
            .filter(Boolean)
            .join(" ")}
        />
      </RadioStyles.StyledSVG>
    </StyledRadio>
    {children}
  </>
);

export { RadioStyles, BaseRadioStyles };

export default Radio;
