import React, { useRef, useState } from "react";
import { useScroll } from "@diana-ui/hooks";
import classnames from "classnames";
import { PrimaryButton, SecondaryButton } from "components/common/button";
import { Checkbox } from "components/common/checkbox";
import { Icon } from "components/common/icon";
import { Radio, RadioGroup } from "components/common/radio";
import { ErrorTextInput as TextInput } from "components/common/text-input";
import TextArea from "components/common/text-input/TextArea";
import { Button4, Headline1, Paragraph2, SmallText } from "components/common/typography/Typography";
import ContentSection from "components/modules/content-section/ContentSection";
import Hero from "components/modules/hero/Hero";
import { Page } from "components/page/Page";
import { motion } from "framer-motion";
import { validateEmail } from "helpers/helpers";
import { smoothScrollingValue } from "helpers/scrolling";
import { useTheme } from "styled-components";
import connectDesktop from "../assets/images/connect_desktop.png";
import connectResponsive from "../assets/images/connect_responsive.png";

const ConnectContent = () => {
  const theme = useTheme();
  const scroll = useScroll();
  const formRef = useRef<HTMLFormElement>();
  const [firstSend, setFirstSend] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [formSent, setFormSent] = useState(false);
  const [check, setCheck] = useState(true);

  const focusForm = () => {
    if (scroll.scroll < formRef.current?.getBoundingClientRect().top) {
      smoothScrollingValue(formRef.current?.offsetTop - 100);
    }
  };

  const onSubmit = e => {
    const myForm = e.target;
    e.preventDefault();

    setFirstSend(true);
    if (!validateEmail(email) || firstName.length < 2 || lastName.length < 2 || !check) {
      return;
    }

    const formData: any = new FormData(myForm);
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: new URLSearchParams(formData).toString(),
    })
      .then(() => {
        setFormSent(true);
      })
      .catch(error => alert(error));
  };

  const resetForm = () => {
    setFormSent(false);
    setFirstSend(false);
    setEmail("");
    setFirstName("");
    setLastName("");
    setCheck(false);
    setMessage("");
  };

  const Hint = (validation, text) => {
    return validation ? (
      <motion.div
        className="flex items-center gap-xs"
        initial={{ paddingBottom: 0, opacity: 0 }}
        animate={{ paddingBottom: 16, opacity: 1 }}
        exit={{ paddingBottom: 0, opacity: 0 }}>
        <Icon name="Warning" />
        <SmallText>{text}</SmallText>
      </motion.div>
    ) : (
      ""
    );
  };

  return (
    <>
      <Hero
        id={"Connect - Hero"}
        backgroundColor={theme.colors.blue["400"]}
        image={{ file: { url: connectDesktop }, title: "Connect" }}
        responsiveImage={{
          file: { url: connectResponsive },
          title: "Connect",
        }}
        noLimitImage
        textColor={theme.colors.white}
        heroTitle={{ title: "LET’S\nCONNECT" }}
        description={{
          description:
            "Get answers to questions about membership, event, support and everything else.",
        }}
      />
      <ContentSection
        id={"Connect - Form"}
        title={"Send a message"}
        titleDescription={{
          titleDescription:
            "The Peer Group is a private community, exclusively for global multinational companies excluding SAP and Business Integrators/System Integrators/partners.",
        }}
        useDivider={true}
        content={
          formSent ? (
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              className="flex flex-col max-w-[430px] md:max-w-[unset] items-start gap-md">
              <Icon name={"CheckSuccess"} color={theme.colors.black} />
              <Headline1>Thank you for reaching out!</Headline1>
              <Paragraph2>
                Your message has been successfully received and we will respond to you as soon as
                possible.
              </Paragraph2>
              <div className="mt-md">
                <SecondaryButton leftIcon="ArrowLeft" onClick={resetForm}>
                  Back
                </SecondaryButton>
              </div>
            </motion.div>
          ) : (
            <form
              ref={formRef}
              className="w-full max-w-[430px] md:max-w-[unset]"
              name="contact-form"
              data-netlify="true"
              data-form="contact-form"
              method="POST"
              onClick={focusForm}
              onSubmit={onSubmit}
              netlify-honeypot="bot-field">
              <input type="hidden" name="form-name" value="contact-form" />

              <RadioGroup name="type" initialValue="general">
                <Radio value="general" label="General" />
                <Radio value="membership" label="Membership" />
                <Radio value="event" label="Event" />
              </RadioGroup>

              <div className="flex flex-col mt-xl gap-xs">
                <TextInput
                  label="First name"
                  name="first_name"
                  hint={Hint(
                    firstSend && firstName.length < 2,
                    "Name needs to be at least 2 letters",
                  )}
                  error={firstSend && firstName.length < 2}
                  value={firstName}
                  onChange={e => setFirstName(e.target.value)}
                  required
                  suffixIcon={<SmallText>Required</SmallText>}
                />
                <TextInput
                  label="Last name"
                  name="last_name"
                  hint={Hint(
                    firstSend && lastName.length < 2,
                    "Name needs to be at least 2 letters",
                  )}
                  error={firstSend && lastName.length < 2}
                  value={lastName}
                  onChange={e => setLastName(e.target.value)}
                  required
                  suffixIcon={<SmallText>Required</SmallText>}
                />
                <TextInput
                  label="Email address"
                  name="email"
                  type="email"
                  hint={Hint(firstSend && !validateEmail(email), "Email must be in a valid format")}
                  error={firstSend && !validateEmail(email)}
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                  required
                  suffixIcon={<SmallText>Required</SmallText>}
                />
                <div className="flex items-center gap-xs">
                  <TextInput
                    className="w-[64px]"
                    name="prefix-phone"
                    type="tel"
                    prefixIcon={<SmallText>+</SmallText>}
                  />
                  <TextInput
                    className="flex-1"
                    label="Phone number"
                    type="tel"
                    name="phone-number"
                  />
                </div>
                <TextInput label="Your company" name="company" />
                <TextArea
                  label="Your message"
                  name="message"
                  value={message}
                  onChange={e => setMessage(e.target.value)}
                />
                {/*<div className="flex mt-lg">*/}
                {/*  <Checkbox*/}
                {/*    name="check"*/}
                {/*    checked={check}*/}
                {/*    error={firstSend && !check}*/}
                {/*    onChange={e => setCheck(e.target.checked)}*/}
                {/*  />*/}
                {/*  <Button4 className={classnames({ "text-red-300": firstSend && !check })}>*/}
                {/*    I’m aware that by sending the application, I approve the{" "}*/}
                {/*    <a className="text-blue-300 underline" href="/terms" target="blank">*/}
                {/*      Terms & Conditions*/}
                {/*    </a>*/}
                {/*  </Button4>*/}
                {/*</div>*/}
                <PrimaryButton className="mt-lg" type="submit">
                  Submit
                </PrimaryButton>
              </div>
            </form>
          )
        }
      />
    </>
  );
};

const ConnectPage = () => {
  return (
    <Page headerOverlapped={true} headerTextColorInverse={true}>
      <ConnectContent />
    </Page>
  );
};

export default ConnectPage;
