import { RadioGroup as BaseRadioGroup, RadioGroupStyles } from "@diana-ui/radio";
import styled, { css } from "styled-components";

export { RadioGroupStyles };

export default styled(BaseRadioGroup)(
  ({ theme }) => css`
    display: flex;
    column-gap: ${theme.spaceUnit.xl};
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  `,
);
