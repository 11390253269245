import React, { useState } from "react";
import { Textarea as BaseTextArea, TextareaStyles } from "@diana-ui/textarea";
import { FontWeight } from "components/common/typography/fonts";
import styled, { css } from "styled-components";

const StyledTextArea = styled(BaseTextArea)(
  ({ value, theme, focused }) => css`
    display: flex;
    flex-direction: column;
    border: none;
    height: auto;
    margin: 0;
    padding: 0;
    background-color: transparent;
    position: relative;

    font-size: 14px;
    font-weight: ${FontWeight.REGULAR};
    line-height: 112%;
    font-family: "aktiv-grotesk";
    color: ${theme.colors.neutral["500"]};
    transition: 0.3s all;
    margin-top: 8px;

    ${TextareaStyles.SyledTextarea} {
      font-size: 14px;
      font-weight: ${FontWeight.REGULAR};
      line-height: 112%;
      font-family: "aktiv-grotesk";
      padding: 0;
      margin-top: 4px;
      border: 0;
      border-bottom: 1px solid ${theme.colors.neutral["300"]};
      color: ${theme.colors.black};
      border-radius: 0;
      box-sizing: border-box;
      transition: 0.3s all;
      height: 26px;
      ${value?.length > 0 &&
      css`
        height: 100px;
      `}

      &:hover {
        background-color: ${theme.colors.white};
      }
      &:hover,
      &:focus {
        border-color: ${theme.colors.black};
      }
      &:focus {
        height: 100px;
      }
    }

    ${TextareaStyles.Label} {
      position: absolute;
      top: 4px;
      pointer-events: none;
      transition: 0.3s all;
      ${(value?.length > 0 || focused) &&
      css`
        font-size: 12px;
        line-height: 140%;
        top: -16px;
      `}
    }
  `,
);

const TextArea = props => {
  const [focused, setFocused] = useState(false);
  return (
    <StyledTextArea
      {...props}
      focused={focused}
      onFocus={() => {
        setFocused(true);
        props?.onFocus?.();
      }}
      onBlur={() => {
        setFocused(false);
        props?.onBlur?.();
      }}
    />
  );
};

TextArea.displayName = "TextArea";

export default TextArea;
