import styled, { css } from "styled-components";
import { TextInput as BaseTextInput, TextInputStyles } from "@diana-ui/textinput";
import { FontWeight } from "components/common/typography/fonts";
import { ErrorTextInputStyles } from "components/common/text-input/ErrorTextInput";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const baseTextInputStylesheet = ({
  theme,
  label,
  disabled,
  hasError,
  prefixIcon,
}: // eslint-disable-next-line @typescript-eslint/no-explicit-any
any) => css`
  box-sizing: border-box;
  border-bottom: 1px solid ${theme.colors.neutral["300"]};
  margin-left: 0;
  margin-right: 0;
  height: 40px;
  padding: 0;
  transition: 0.3s all;

  &.focus,
  :hover {
    border-bottom: 1px solid;
    border-color: ${theme.colors.black};
  }

  ${TextInputStyles.InputContainer} {
    flex: 1;

    ${TextInputStyles.Input} {
      font-size: 14px;
      font-weight: ${FontWeight.REGULAR};
      line-height: 112%;
      font-family: "aktiv-grotesk";
    }
  }

  ${TextInputStyles.LabelContainer} {
    height: 42px;
    width: calc(100% - ${theme.spaceUnit.xs});
    left: 0;

    ${TextInputStyles.TextLabel} {
      padding: 0;
      font-size: 14px;
      font-weight: ${FontWeight.REGULAR};
      line-height: 112%;
      font-family: "aktiv-grotesk";
      color: ${hasError ? theme.colors.red["300"] : ""};
    }
  }

  ${TextInputStyles.Legend} {
    height: 2px;
    text-overflow: ellipsis;

    @media screen and (max-width: 700px) {
      height: 3px;
    }

    ${!label &&
    css`
      padding: 0;
    `}
  }

  ${TextInputStyles.TextLabel} {
    text-overflow: ellipsis;
  }

  ${TextInputStyles.SuffixWrapper} {
    display: flex;

    font-size: 12px;
    font-weight: ${FontWeight.REGULAR};
    line-height: 140%;
    font-family: "aktiv-grotesk";

    color: ${hasError ? theme.colors.red["300"] : ""};
  }

  ${TextInputStyles.PrefixWrapper} {
    display: flex;
  }

  ${disabled &&
  css`
    background-color: ${theme.colors.neutral["200"]};
    border-color: ${theme.colors.neutral["200"]};

    &.focus,
    :hover {
      border-color: ${theme.colors.neutral["200"]};
    }

    ${TextInputStyles.TextLabel} {
      color: ${theme.colors.neutral["500"]};
    }

    ${TextInputStyles.Input} {
      background-color: ${theme.colors.neutral["200"]};
    }
  `}
  ${hasError &&
  css`
    border-color: ${theme.colors.red["300"]};
  `}
  ${prefixIcon &&
  css`
    ${TextInputStyles.TextLabel} {
      padding-left: ${theme.spaceUnit.xl};
    }
  `}
  
  &.active,
  &.focus {
    ${TextInputStyles.TextLabel} {
      z-index: 2;
      padding-left: 0;
      margin-left: -2px;
      font-size: 12px;
      font-weight: ${FontWeight.REGULAR};
      line-height: 140%;
      font-family: "aktiv-grotesk";
    }
  }

  ${TextInputStyles.Input} {
    background-color: transparent;

    &:-webkit-autofill-selected {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.colors.white};
    }

    &:-webkit-autofill {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }

    &:-webkit-autofill:hover {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }

    &:-webkit-autofill:focus {
      box-shadow: inset 0 0 0 1px rgba(255, 255, 255, 0), inset 0 0 0 100px ${theme.colors.white};
      z-index: 1;
    }
  }
`;

const TextInput = styled(BaseTextInput)(baseTextInputStylesheet);

TextInput.displayName = "TextInput";

export { TextInputStyles, baseTextInputStylesheet };

export default TextInput;
