import { ErrorTextInput as BaseErrorTextInput, ErrorTextInputStyles } from "@diana-ui/textinput";
import { FontWeight } from "components/common/typography/fonts";
import styled, { css } from "styled-components";
import TextInput, { baseTextInputStylesheet, TextInputStyles } from "./TextInput";

const ErrorTextInput = styled(BaseErrorTextInput).attrs({
  CustomTextInputComponent: TextInput,
})(
  ({ theme, label, error, hint, disabled, prefixIcon }) => css`
    ${ErrorTextInputStyles.HelperLabel} {
      margin-top: ${theme.spaceUnit.xxs};
      font-weight: ${FontWeight.MEDIUM};
      align-items: flex-start;
      ${theme.typography.smallText}
    }

    ${ErrorTextInputStyles.TypedLabel} {
      color: ${error ? theme.colors.red["300"] : hint ? theme.colors.neutral["300"] : ""};
    }

    ${ErrorTextInputStyles.StyledBaseTextInput} {
      ${baseTextInputStylesheet({
        theme,
        label,
        hasError: error,
        disabled,
        prefixIcon,
      })}
    }
  `,
);

export { ErrorTextInputStyles };

export type { IErrorTextInputProps } from "@diana-ui/textinput";

export default ErrorTextInput;
